import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ReadMore from '../components/read_more';
import BuyNow from '../components/buy_now';

import '../styles/shop.scss';

const getFluidImageData = (query, fileName) => {
    var result = query.edges.find((x) => x.node.childImageSharp.fluid.src.includes(fileName));
    return result.node.childImageSharp.fluid;
}

const ShopPage = ({data}) => (
    <Layout>
        <SEO title="shop" />
        <div className="headerText">
            shop
        </div>
        <div className="shop-content">
            <div className="schedule">
                I roast every Thursday. Order by Wednesday night for a weekend pick-up/ship date.
            </div>
            <div class="flex-container">
                {data.allMarkdownRemark.edges.map(({ node }) => (
                    <div className="shop-link">
                        <Link to={ node.frontmatter.slug } className="image-title" >
                            { node.frontmatter.title }
                            <Img fluid={getFluidImageData(data.imageQuery, node.frontmatter.image)} width="250px" />
                        </Link>
                        <ReadMore to={ node.frontmatter.slug } />
                        <BuyNow to={node.frontmatter.shop_link} />
                    </div>
                ))}
            </div>
        </div>
    </Layout>
);

export const query = graphql `
{
  allMarkdownRemark(
    sort: { order: ASC, fields: [frontmatter___title] }
    limit: 10
  ) {
    edges {
      node {
        frontmatter {
          slug
          title
          image
          shop_link
        }
      }
    }
  }

  imageQuery:
    allFile(filter: {relativeDirectory: { eq: "shop-images" }}) {
        edges {
            node {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
}
`

export default ShopPage;