import React from 'react';

import '../styles/read_more.scss';

const ReadMore = ({to}) => {
    return (
        <div className="read-more-link-container">
            <a href={`${to}`} className="read-more-link">
                &gt;&gt; read more &lt;&lt;
            </a>
        </div>
    )
}

export default ReadMore;